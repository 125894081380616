import React from 'react'

/**
 * REACT POLYFILLS FOR CLIENT/SERVER COMPATIBILITY
 * ==================================================
 * This file handles React compatibility issues between client and server rendering.
 * It ensures hooks like useLayoutEffect work properly in all environments.
 *
 * PRIMARY FIXES:
 * 1. useLayoutEffect polyfill for SSR/CSR compatibility
 * 2. Support for third-party libraries that might depend on these hooks
 *
 * WARNING: Even if we're not directly using libraries with these hooks,
 * we keep this polyfill active to ensure any dynamic imports or lazy-loaded
 * components that might use these hooks will still work properly.
 */

// Create a useIsomorphicLayoutEffect that works in all environments
const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect

// Apply useLayoutEffect polyfill
if (typeof window === 'undefined') {
  // Server-side rendering environment - use useEffect instead
  React.useLayoutEffect = React.useEffect
} else if (!React.useLayoutEffect) {
  // Client-side but useLayoutEffect is missing - fallback to useEffect
  React.useLayoutEffect = React.useEffect
}

/**
 * Function to check and ensure useLayoutEffect exists
 * Can be called directly if needed in specific components
 */
export const ensureUseLayoutEffect = () => {
  if (!React.useLayoutEffect) {
    React.useLayoutEffect = React.useEffect
  }
  return React.useLayoutEffect
}

// Export the isomorphic version of useLayoutEffect for direct use in components
export { useIsomorphicLayoutEffect }

// Export polyfilled React for direct imports if needed
export { React as PolyfillReact }
