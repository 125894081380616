// Import polyfills as early as possible - before any other imports
import './utils/reactPolyfills'

import '@fortawesome/fontawesome-free/css/all.min.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './amplifyconfiguration'
import App from './App'
import './styles/globals.css'

// Verbose logging for debugging
console.log('==========================================')
console.log('🚀 Application bootstrapping started')
console.log('React version:', React.version)
console.log('==========================================')

// Global error handler with detailed logging
const handleError = (event: ErrorEvent) => {
  console.error('🔴 Global error caught:', {
    message: event.error?.message || 'Unknown error',
    stack: event.error?.stack,
    type: event.type,
    timestamp: new Date().toISOString()
  })

  // Prevent the error from bubbling up
  event.preventDefault()
}

// Add global error handlers
window.addEventListener('error', handleError)
window.addEventListener('unhandledrejection', (event) => {
  console.error('🔴 Unhandled promise rejection:', {
    reason: event.reason,
    timestamp: new Date().toISOString()
  })
  event.preventDefault()
})

// Safe import of AWS config - wrapped in try/catch
try {
  require('./amplifyconfiguration')
  console.log('✅ AWS configuration loaded successfully')
} catch (error) {
  console.error('🔴 Error loading AWS configuration:', error)
  console.log('⚠️ Continuing without AWS configuration')
}

// Create a safe rendering function
const renderApp = () => {
  try {
    console.log('🔄 Attempting to render app')
    const root = document.getElementById('root')

    if (root) {
      console.log('✅ Root element found, creating React root')

      // Remove fallback UI if it exists
      const fallback = document.querySelector('.fallback-container')
      if (fallback) {
        fallback.setAttribute('style', 'display: none;')
      }

      ReactDOM.createRoot(root).render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
      )

      console.log('✅ React app mounted successfully')
    } else {
      console.error('🔴 Root element not found')
    }
  } catch (error) {
    console.error('🔴 Fatal error rendering app:', error)
  }
}

// Start the app with error handling after a minimal delay
// This helps ensure the DOM is fully ready
setTimeout(() => {
  renderApp()
  console.log('⏱️ Render triggered after timeout')
}, 50)

// Log successful script load
console.log('✅ main.tsx script executed completely')
