import { ErrorBoundary } from 'react-error-boundary'
import React, { Suspense, useState, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ThemeProvider } from './contexts/ThemeContext'
import { ResetProvider } from './contexts/ResetContext'
import { BrowserRouter as Router } from 'react-router-dom'
// Import React polyfills (handled at entry point)
// import './utils/reactPolyfills'

// Lazy load ThemedApp to reduce initial load impact with retry mechanism
const ThemedApp = React.lazy(() =>
  import('./components/ThemedApp').catch((error) => {
    console.error('Failed to load ThemedApp:', error)
    return import('./components/ThemedApp') // Retry once
  })
)

// Error fallback component
function ErrorFallback({ error }: { error: Error }) {
  console.error('App error:', error)

  return (
    <div
      role="alert"
      style={{
        padding: '20px',
        textAlign: 'center',
        backgroundColor: '#121212',
        color: 'white',
        fontFamily: 'system-ui, sans-serif',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <h2 style={{ marginBottom: '20px' }}>Something went wrong</h2>
      <div
        style={{
          backgroundColor: '#1e1e1e',
          padding: '15px',
          borderRadius: '4px',
          marginBottom: '20px',
          maxWidth: '600px',
          overflowX: 'auto',
          textAlign: 'left'
        }}
      >
        <pre
          style={{
            color: '#e57373',
            margin: 0,
            backgroundColor: 'rgba(40, 40, 40, 0.6)',
            backdropFilter: 'blur(4px)',
            padding: '12px',
            borderRadius: '4px'
          }}
        >
          {error.message || 'An unknown error occurred'}
        </pre>
      </div>
      <button
        onClick={() => window.location.reload()}
        style={{
          padding: '10px 20px',
          backgroundColor: '#2196f3',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '16px'
        }}
      >
        Try again
      </button>
    </div>
  )
}

// Loading fallback component
function LoadingFallback() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#ffffff',
        color: '#121212',
        fontFamily: 'Inter, system-ui, sans-serif',
        flexDirection: 'column',
        padding: '20px'
      }}
    >
      <h2 style={{ marginBottom: '20px' }}>Loading application...</h2>
      <div
        style={{
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          border: '5px solid #f3f3f3',
          borderTop: '5px solid #3498db',
          animation: 'spin 1s linear infinite'
        }}
      ></div>
      <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  )
}

// Initialize React Query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      retry: 1
    }
  }
})

function App() {
  const [appInitialized, setAppInitialized] = useState(false)

  // Basic app initialization check to catch early errors
  useEffect(() => {
    try {
      // Log initialization attempt
      console.log('Initializing App component')

      // Set app as initialized after short delay to ensure everything is ready
      const timer = setTimeout(() => {
        setAppInitialized(true)
        console.log('App initialized successfully')
      }, 500)

      return () => clearTimeout(timer)
    } catch (error) {
      console.error('Error during app initialization:', error)
      // Still mark as initialized so we render something
      setAppInitialized(true)
    }
  }, [])

  if (!appInitialized) {
    return <LoadingFallback />
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ThemeProvider>
        <ResetProvider>
          <Router>
            <Suspense fallback={<LoadingFallback />}>
              <QueryClientProvider client={queryClient}>
                <ThemedApp />
              </QueryClientProvider>
            </Suspense>
          </Router>
        </ResetProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default App
