import { Amplify } from 'aws-amplify'

const endpoint = import.meta.env.VITE_APPSYNC_ENDPOINT
const region = 'us-east-1'
const apiKey = import.meta.env.VITE_APPSYNC_API_KEY

// Log configuration information for debugging
console.log('Amplify Configuration Status:', {
  endpointExists: !!endpoint,
  apiKeyExists: !!apiKey,
  environment: import.meta.env.MODE || 'unknown'
})

// Handle missing environment variables gracefully
if (!endpoint || !apiKey) {
  console.warn(
    'Missing required environment variables for AppSync. Using fallback configuration.'
  )

  // Configure with fallback options
  Amplify.configure({
    API: {
      GraphQL: {
        endpoint: endpoint || 'https://fallback-endpoint.example.com',
        region,
        apiKey: apiKey || 'fallback-key',
        defaultAuthMode: 'apiKey'
      }
    }
  })
} else {
  // Configure with actual values
  Amplify.configure({
    API: {
      GraphQL: {
        endpoint,
        region,
        apiKey,
        defaultAuthMode: 'apiKey'
      }
    }
  })
}

export { endpoint, region, apiKey }
